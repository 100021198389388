import React, { useEffect, useState } from "react";
import "./register.scss";
import { Otptimer } from "otp-timer-ts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  getOtpRequest,
  verifyOtpRequestSignup,
} from "../../redux/slices/auth/RegisterSlice";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [otpValues, setOtpValues] = useState({ otp: "" });
  const [loginInputError, setLoginInputError] = useState("");
  const otpError = useSelector((state) => state.signup.otpError);
  const secondStep = useSelector((state) => state.signup.secondStep);
  const otpInputDisabled = useSelector(
    (state) => state.signup.otpInputDisabled
  );
  const isAuthenticated = useSelector((state) => state.signup.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      // Navigate to /user-details when OTP is verified successfully
      navigate("/user-details");
    }
  }, [isAuthenticated, navigate]); // Run this effect when isAuthenticated changes

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setLoginInputError("");
  };

  const handleGetOtp = (e) => {
    e.preventDefault();
    if (!inputValue) {
      setLoginInputError("Please enter a value.");
    } else {
      const userInput = { mobile: inputValue };
      dispatch(getOtpRequest(userInput));
    }
  };

  const handleOtpChange = (e) => {
    setOtpValues({ ...otpValues, [e.target.name]: e.target.value });
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    const userOtp = {
      mobile: inputValue,
      otp: otpValues.otp,
    };
    dispatch(verifyOtpRequestSignup(userOtp));
  };
  
  const handleKeyDown = (e) => {
    if (
      !(e.key >= "0" && e.key <= "9") &&
      e.key !== "Backspace" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "Tab" &&
      e.key !== "Delete"
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="register-container-wrapper flex-center">
        <div className="form-container-wrapper flex-center">
          <div className="login-heading-container">
            <h1 className="header-one">Sign Up</h1>
            <p>
              Create your profile and start hosting your pet with our trusted
              hosts.
            </p>
          </div>
          <div className="login-form-container flex-center">
            <input
              type="text"
              name="phone_number"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Enter your phone number"
              maxLength="10" // Restrict input to 10 digits
            />

            <input
              disabled={otpInputDisabled}
              type="text"
              name="otp"
              value={otpValues.otp}
              onChange={handleOtpChange}
              placeholder="Enter OTP"
            />

            {otpInputDisabled === false ? (
              <button onClick={handleVerifyOtp} className="login-button">
                NEXT
              </button>
            ) : (
              <button onClick={handleGetOtp} className="login-button">
                Get OTP
              </button>
            )}
          </div>
          <p className="sign-up-navigation">
            Already have an Account? <Link to="/login">Sign In</Link>
          </p>
          <div className="privacy-policy-acceptance">
            <p>
              By signing up & logging in, I agree with Woofily's{" "}
              <Link to="/terms-and-conditions">
                Terms of Service & Privacy & Policy
              </Link>
            </p>
          </div>
        </div>
        <div className="content-container-wrapper flex-center">
          <div className="main-img-wrapper">
            <img src="/assets/images/auth/man-with-dog.png" alt="" />
            <div className="paw-overlay flex-center">
              <img src="/assets/images/auth/yellow-paw.png" alt="" />
            </div>
            {/* <div className="content-carousel-wrapper">
              <h1 className="header-one">Sign In</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut,
                exercitationem pariatur? Animi harum rem libero nulla explicabo
                id sit natus voluptatibus error, quaerat magnam temporibus
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
